define("discourse/plugins/automation/admin/components/fields/da-categories-field", ["exports", "@ember/helper", "@ember/object", "discourse/models/category", "select-kit/components/category-selector", "discourse/plugins/automation/admin/components/fields/da-base-field", "discourse/plugins/automation/admin/components/fields/da-field-description", "discourse/plugins/automation/admin/components/fields/da-field-label", "@ember/template-factory", "@ember/component"], function (_exports, _helper, _object, _category, _categorySelector, _daBaseField, _daFieldDescription, _daFieldLabel, _templateFactory, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _CategoriesField;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let CategoriesField = _exports.default = (_class = (_CategoriesField = class CategoriesField extends _daBaseField.default {
    get categories() {
      const ids1 = this.args.field?.metadata?.value || [];
      return ids1.map(id1 => _category.default.findById(id1)).filter(Boolean);
    }
    onChangeCategories(categories1) {
      this.mutValue(categories1.mapBy("id"));
    }
  }, (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{! template-lint-disable no-redundant-fn }}
      <section class="field categories-field">
        <div class="control-group">
          <DAFieldLabel @label={{@label}} @field={{@field}} />
  
          <div class="controls">
            <CategorySelector
              @categories={{this.categories}}
              @onChange={{fn this.onChangeCategories}}
              @options={{hash clearable=true disabled=@field.isDisabled}}
            />
  
            <DAFieldDescription @description={{@description}} />
          </div>
        </div>
      </section>
    
  */
  {
    "id": "3OpS3fT0",
    "block": "[[[1,\"\\n\"],[1,\"    \"],[10,\"section\"],[14,0,\"field categories-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@categories\",\"@onChange\",\"@options\"],[[30,0,[\"categories\"]],[28,[32,2],[[30,0,[\"onChangeCategories\"]]],null],[28,[32,3],null,[[\"clearable\",\"disabled\"],[true,[30,2,[\"isDisabled\"]]]]]]],null],[1,\"\\n\\n          \"],[8,[32,4],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/fields/da-categories-field.js",
    "scope": () => [_daFieldLabel.default, _categorySelector.default, _helper.fn, _helper.hash, _daFieldDescription.default],
    "isStrictMode": true
  }), _CategoriesField), _CategoriesField), (_applyDecoratedDescriptor(_class.prototype, "onChangeCategories", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeCategories"), _class.prototype)), _class);
});