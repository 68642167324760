define("discourse/plugins/automation/admin/components/fields/da-users-field", ["exports", "@ember/helper", "select-kit/components/user-chooser", "discourse/plugins/automation/admin/components/fields/da-base-field", "discourse/plugins/automation/admin/components/fields/da-field-description", "discourse/plugins/automation/admin/components/fields/da-field-label", "@ember/template-factory", "@ember/component"], function (_exports, _helper, _userChooser, _daBaseField, _daFieldDescription, _daFieldLabel, _templateFactory, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _UsersField;
  class UsersField extends _daBaseField.default {}
  _exports.default = UsersField;
  _UsersField = UsersField;
  (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <section class="field users-field">
        <div class="control-group">
          <DAFieldLabel @label={{@label}} @field={{@field}} />
  
          <div class="controls">
            <UserChooser
              @value={{@field.metadata.value}}
              @onChange={{this.mutValue}}
              @options={{hash
                excludeCurrentUser=false
                disabled=@field.isDisabled
                allowEmails=true
              }}
            />
  
            {{#if @field.metadata.allowsAutomation}}
              <span class="help-inline error">{{@field.metadata.error}}</span>
            {{/if}}
  
            <DAFieldDescription @description={{@description}} />
          </div>
        </div>
      </section>
    
  */
  {
    "id": "H4iZkYKs",
    "block": "[[[1,\"\\n    \"],[10,\"section\"],[14,0,\"field users-field\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@label\",\"@field\"],[[30,1],[30,2]]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n          \"],[8,[32,1],null,[[\"@value\",\"@onChange\",\"@options\"],[[30,2,[\"metadata\",\"value\"]],[30,0,[\"mutValue\"]],[28,[32,2],null,[[\"excludeCurrentUser\",\"disabled\",\"allowEmails\"],[false,[30,2,[\"isDisabled\"]],true]]]]],null],[1,\"\\n\\n\"],[41,[30,2,[\"metadata\",\"allowsAutomation\"]],[[[1,\"            \"],[10,1],[14,0,\"help-inline error\"],[12],[1,[30,2,[\"metadata\",\"error\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n          \"],[8,[32,3],null,[[\"@description\"],[[30,3]]],null],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@label\",\"@field\",\"@description\"],false,[\"if\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/automation/admin/components/fields/da-users-field.js",
    "scope": () => [_daFieldLabel.default, _userChooser.default, _helper.hash, _daFieldDescription.default],
    "isStrictMode": true
  }), _UsersField);
});